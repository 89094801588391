import React from 'react';
import styled from 'styled-components';
import breakpoints from '../utils/breakpoints';

const ScheduleSection = () => (
  <Wrapper>
    <RainbowBar />
    <Title>
      2019 Stoli Key West Cocktail Classic Pride Week Finale Schedule of Events
    </Title>
    <GuestsSection>
      Special guest hosts, performers, and judges for 2019:&nbsp;
      <Bold>LeeAnne Locken</Bold>
      &nbsp;(Real Housewives of Dallas),&nbsp;
      <Bold>LaToya London</Bold>
      &nbsp;(American Idol),&nbsp;
      <Bold>Bruce Vilanch</Bold>
      &nbsp;(Hollywood Squares/everything else!),&nbsp;
      <Bold>Debby Holiday</Bold>
      &nbsp;(Billboard Artist/Songwriter: Dive, Joyful Sound),&nbsp;
      <Bold>Jennifer McClain</Bold>
      &nbsp;(Cabaret/stage star),
      <Bold>Sushi</Bold>
      &nbsp;(CNN New Year’s
      Eve High Heel Drop), and&nbsp;
      <Bold>Lance Bass</Bold>
      &nbsp;(NSYNC)
    </GuestsSection>
    <DateTitle>Tuesday, June 4</DateTitle>
    <ScheduleTitle>Sunset Welcome Cruise of Champions on The Fury</ScheduleTitle>
    <Text>Boarding at 5:30pm | Depart at 6pm sharp</Text>
    <Text>www.furycat.com</Text>
    <Text>
      (Invite only: bartenders + guests, judges, island partners and hosts, special VIP’s).
    </Text>
    <SmallerText style={{ maxWidth: '600px' }}>
      Strict limit 128 people. To WIN two tickets to join us (two sets will be given away)
      e-mail keywestcruise@gmail.com with subject “Enter to Win Tickets June 4” and a sentence
      or two about why you would like to join our Stoli Key West Cocktail Classic 2019
      bartenders, friends, and fans on the 2019 Sunset Cruise of Champions. Please
      include your name and best number to contact. You MUST already be in Key
      West June 4 to win and receive tickets. Contest closes May 31 at 3pm ET and
      winners will be notified by June 1.
    </SmallerText>
    <ScheduleTitle style={{ marginTop: '25px' }}>
      After Party at The Saint Hotel | 9:30pm (All Welcome!)
    </ScheduleTitle>
    <SmallText>
      417 Eaton Street, Key West FL 33040
    </SmallText>
    <SmallText>
      (305) 294-3200 www.sainthotels.com/key-west/
    </SmallText>

    <DateTitle>Wednesday, June 5</DateTitle>
    <ScheduleTitle>
      Pride Kickoff and Bartender Welcome Party at Island House Key West
    </ScheduleTitle>
    <Text>
      5-9pm | Hosted Stoli bar and appetizers until 7pm (All Welcome!)
    </Text>
    <SmallText>
      $20 Donation to support KWBG/Key West Pride
    </SmallText>
    <SmallText>
      1129 Fleming St, Key West, FL 33040
    </SmallText>
    <SmallText>
      (305) 294-6284 www.islandhousekeywest.com
    </SmallText>

    <DateTitle>Thursday, June 6</DateTitle>
    <ScheduleTitle>
      Semifinals Event 1 at Aqua | 5-8pm
    </ScheduleTitle>
    <SmallText>
      711 Duval St, Key West, FL 33040
    </SmallText>
    <SmallText>
      (305) 294-0555 www.aquakeywest.com
    </SmallText>
    <SmallText>
      (RSVP check-ins and audience blind sampling at 5pm, show-time 6pm) RSVP at
    </SmallText>
    <SmallText>
      www.facebook.com/events/
    </SmallText>
    <ScheduleTitle style={{ marginTop: '25px' }}>
      After Party at Sidebar | 8:30pm
    </ScheduleTitle>
    <SmallText>
      504 Angela Street, attached to Aqua
    </SmallText>
    <SmallText>
      (305) 294-0555 www.sidebarkeywest.com
    </SmallText>

    <DateTitle>Friday, June 7</DateTitle>
    <ScheduleTitle>
      Semifinals Event 2 at 801 Bourbon | 5-8pm
    </ScheduleTitle>
    <SmallText>801 Duval Street, Key West, FL 33040</SmallText>
    <SmallText>(305) 294-4737 www.801.bar</SmallText>
    <SmallText>
      (RSVP check-ins and audience blind sampling at 5pm,
      show-time 6pm) RSVP at
    </SmallText>
    <SmallText>www.facebook.com/events/</SmallText>
    <ScheduleTitle style={{ marginTop: '25px' }}>
      The ALL-NEW Stoli Key West Bartender Battle at Bourbon Street Pub | 9pm
    </ScheduleTitle>
    <Text style={{ maxWidth: '600px' }}>
      Local Key West Bar Stars battle it out for a paid appearance as mixologist judge for the
      Stoli Key West Pride Main Stage Finale!
    </Text>
    <SmallText>724 Duval St, Key West, FL 33040</SmallText>
    <SmallText>(305) 294-9354  www.bourbonstreetpub.com</SmallText>
    <Text style={{ maxWidth: '600px', marginTop: '25px' }}>
      Check in and sample cocktails from 8:30-10. Vote for your favorite from 9-10:30pm, and
      the winner will be announced at 11pm. All Invited!
    </Text>

    <DateTitle>Saturday, June 8</DateTitle>
    <ScheduleTitle>
      Featuring special guest judge LANCE BASS
    </ScheduleTitle>

    <ScheduleTitle>
      The Pride Main Stage Finale live on Duval Street in front of
    </ScheduleTitle>
    <ScheduleTitle>
      Bourbon Street Pub | 7:30pm
    </ScheduleTitle>
    <SmallText>724 Duval St, Key West, FL 33040</SmallText>

    <DateTitle>Sunday, June 9</DateTitle>
    <ScheduleTitle>Key West Pride Parade | 5pm, down Duval Street</ScheduleTitle>
    <Text>
      Featuring our 2019 Champion leading as Honorary Grand Marshall, followed by all our Bar
      Stars and Special Guests
    </Text>
    <Text>After party and charity check presentation at LaTeDa until 8pm.</Text>
    <SmallText>1125 Duval Street, Key West, FL 33040</SmallText>
    <SmallText>(305) 296-6706 www.lateda.com</SmallText>
  </Wrapper>
);

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-family: 'Tahoma';
  font-size: 14px;
  margin-bottom: 20px;
  @media (max-width: ${breakpoints.sm.max}px) {
    margin: 0 7px;
  }
`;

const RainbowBar = styled.div`
  width: 93px;
  height: 7px;
  background: linear-gradient(to right, blue 17%, green 40%, yellow 60%, red 100%);
`;

const Title = styled.div`
  font-family: 'Tahoma-Bold';
  color: #D9B86E;
  font-size: 25px;
  letter-spacing: 3px;
  line-height: 35px;
  max-width: 600px;
  margin-top: 40px;
  margin-bottom: 40px;

  @media (max-width: ${breakpoints.sm.max}px) {
    font-size: 20px;
    line-height: 25px;
    letter-spacing: 2px;
  }
`;

const Bold = styled.span`
  font-family: 'Tahoma-Bold';
`;

const GuestsSection = styled.div`
  max-width: 800px;
  @media (max-width: ${breakpoints.sm.max}px) {
    font-size: 13px;
  }
`;

const DateTitle = styled.div`
  font-size: 25px;
  font-family: 'Tahoma-Bold';
  margin: 40px 0 35px;
  @media (max-width: ${breakpoints.sm.max}px) {
    font-size: 20px;
  }
`;

const ScheduleTitle = styled.div`
  font-size: 15px;
  font-family: 'Tahoma-Bold';
  letter-spacing: 1px;
`;

const Text = styled.div`
  font-size: 14px;
`;

const SmallText = styled.div`
  font-size: 12px;
`;

const SmallerText = styled.div`
  font-size: 11px;
  line-height: 17px;
`;


export default ScheduleSection;
