import React, { Component } from 'react';
import Modal from 'react-modal';
import styled from 'styled-components';
import { isEmpty, partition, propOr } from 'lodash/fp';

import '../components/layout.css';
import RoundClose from '../assets/svg/round-close-24px.svg';
import Spinner from '../assets/images/spinner.gif';
import BirthDateModal from '../components/BirthDateModal';
import Footer from '../components/Footer';
import ScheduleSection from '../components/ScheduleSection';
import SEO from '../components/seo';
import api from '../services/api';
import breakpoints from '../utils/breakpoints';
import colors from '../utils/colors';
import { todayFormatted, startDate, endDate } from '../utils/votingDates';
import events from '../services/events';
import keyWest from '../assets/images/key-west-logo.png';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    transform: 'translate(-50%, -50%)',
    padding: '0',
    border: 'none',
    borderRadius: '3px',
    boxShadow: '0 0 15px 1px rgba(53, 53, 53, 0.5)',
    color: '#131313',
    width: '90%',
    maxWidth: '470px',
    height: '90%',
    maxHeight: '290px',
  },
};

Modal.setAppElement('#___gatsby');

const formatDate = date => [
  date.getMonth(),
  date.getDate(),
  date.getFullYear(),
].join('-');

const charsToUpper = (limit, str = '') => [
  str.slice(0, limit).toUpperCase(),
  str.length > limit ? '...' : '',
].join('');

class FanFavourite extends Component {
  state = {
    events: [],
    accessToken: null,
    votedBartenderId: null,
    votingBartenderId: null,
    showBirthDateModal: true,
    showLogIntoFBModal: false,
    showVoteAcceptedModal: false,
    showVoteRejectedModal: false,
    showVoteEndedModal: false,
  }

  componentDidMount = () => {
    const voteDate = localStorage.getItem('voteDate');
    const bartenderId = localStorage.getItem('bartenderId');
    if (voteDate !== formatDate(new Date())) {
      localStorage.removeItem('voteDate');
      localStorage.removeItem('bartenderId');
    }
    this.setState({
      voteDate,
      votedBartenderId: bartenderId ? Number(bartenderId) : null,
      showBirthDateModal: !localStorage.getItem('modalAccepted'),
    });

    events.fetchEvents().then((r) => {
      const [eventsWithWinners, events] = partition('winner', r.data);
      this.setState({ events: [...eventsWithWinners, ...events] });
    });

    window.fbAsyncInit = () => {
      window.FB.init({
        appId: '338420560365233',
        cookie: true,
        autoLogAppEvents: true,
        xfbml: true,
        version: 'v3.3',
      });

      window.FB.getLoginStatus(r => this.setState({
        accessToken: propOr(null, 'authResponse.accessToken', r),
      }));
    };

    /* eslint-disable */
    (function(d, s, id) {
      var js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      js = d.createElement(s); js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'facebook-jssdk'));
    /* eslint-enable */
  }

  onBirthDateModalSubmit = () => {
    localStorage.setItem('modalAccepted', true);
    this.setState({ showBirthDateModal: false });
  }

  handleOnVoteClick = bartenderId => async () => {
    const { accessToken, voteDate, votedBartenderId: currentBartenderId } = this.state;
    const today = formatDate(new Date());

    if (todayFormatted < startDate) {
      this.setState({ showVoteNotStartedModal: true });
      return;
    }

    if (todayFormatted > endDate) {
      this.setState({ showVoteEndedModal: true });
      return;
    }

    if (today === voteDate && currentBartenderId) {
      this.setState({ showVoteRejectedModal: true });
      return;
    }

    if (!accessToken) {
      this.setState({ showLogIntoFBModal: true });
      window.FB.login(r => this.setState({
        showLogIntoFBModal: false,
        accessToken: propOr(null, 'authResponse.accessToken', r),
      }, () => r.status === 'connected' && this.handleOnVoteClick(bartenderId)()));
      return;
    }

    let showVoteAcceptedModal = false;
    let showVoteRejectedModal = false;
    let votedBartenderId = bartenderId;
    this.setState({ votingBartenderId: bartenderId });
    try {
      const { data: d } = await api.post('/api/v1/fan_votes', {
        access_token: accessToken,
        bartender_id: votedBartenderId,
      });
      showVoteAcceptedModal = d.message === 'Vote accepted';
      if (showVoteAcceptedModal) {
        localStorage.setItem('voteDate', today);
        localStorage.setItem('bartenderId', votedBartenderId);
      }
    } catch ({ response: { data: d } }) {
      showVoteRejectedModal = d.fb_user_id[0] === 'You can only vote once a day!';
      [votedBartenderId] = d.bartender_id;
    }

    this.setState({
      showVoteAcceptedModal,
      showVoteRejectedModal,
      votedBartenderId,
      votingBartenderId: null,
    });
  }

  renderLoginIntoFBModal = () => {
    const { showLogIntoFBModal } = this.state;

    return (
      <Modal
        isOpen={showLogIntoFBModal}
        style={customStyles}
        overlayClassName="overlay-dark"
      >
        <VotedModalActions />

        <VotedModalContent>
          <VotedModalHeader>Log in with Facebook</VotedModalHeader>

          <VotedModalBody>
            Log into your Facebook account to authenticate your vote.
          </VotedModalBody>
        </VotedModalContent>
      </Modal>
    );
  }

  renderVotedModal = () => {
    const { showVoteAcceptedModal, showVoteRejectedModal } = this.state;

    return (
      <Modal
        overlayClassName="overlay-dark"
        isOpen={showVoteAcceptedModal || showVoteRejectedModal}
        style={customStyles}
      >
        <VotedModalActions>
          <VotedModalButton
            onClick={() => this.setState({
              showVoteAcceptedModal: false,
              showVoteRejectedModal: false,
            })}
          >
            <RoundClose />
          </VotedModalButton>
        </VotedModalActions>

        <VotedModalContent>
          <VotedModalHeader>
            {showVoteRejectedModal ? 'You can only vote once a day!' : 'Thanks for voting!'}
          </VotedModalHeader>

          <VotedModalBody>
            {showVoteRejectedModal && 'Come back tomorrow to vote again for your favourite bartender. '}
            The two bartenders with the most votes receive immunity from
            elimination in Key West Semi-Final events. One will be awarded
            title of 2019 Stoli Key West Fan Favourite Bartender at the Key
            West Pride Finale on June 8th.
          </VotedModalBody>
        </VotedModalContent>
      </Modal>
    );
  }

  renderVotingNotStartedModal = () => {
    const { showVoteNotStartedModal } = this.state;

    return (
      <Modal
        overlayClassName="overlay-dark"
        isOpen={showVoteNotStartedModal}
        style={customStyles}
      >
        <VotedModalActions>
          <VotedModalButton
            onClick={() => this.setState({
              showVoteNotStartedModal: false,
            })}
          >
            <RoundClose />
          </VotedModalButton>
        </VotedModalActions>

        <VotedModalContent>
          <VotedModalHeader>
            Voting hasn’t started yet!
          </VotedModalHeader>

          <VotedModalBody>
            Fan Favourite voting opens on Wednesday, May 22nd at 3pm ET
            (Noon PT) and closes on Thursday, May 30th at 3pm ET (Noon PT).

            <div style={{ marginTop: '20px' }}>
              Check back then to cast your vote!
            </div>
          </VotedModalBody>
        </VotedModalContent>
      </Modal>
    );
  }

  renderVotingEndedModal = () => {
    const { showVoteEndedModal } = this.state;

    return (
      <Modal
        overlayClassName="overlay-dark"
        isOpen={showVoteEndedModal}
        style={customStyles}
      >
        <VotedModalActions>
          <VotedModalButton
            onClick={() => this.setState({
              showVoteEndedModal: false,
            })}
          >
            <RoundClose />
          </VotedModalButton>
        </VotedModalActions>

        <VotedModalContent>
          <VotedModalHeader>
            Sorry, voting is now closed!
          </VotedModalHeader>

          <VotedModalBody>
            Fan Favourite voting ended Thursday, May 30th at 3pm ET (Noon PT).
          </VotedModalBody>
        </VotedModalContent>
      </Modal>
    );
  }

  checkHttp = url => !/^(f|ht)tps?:\/\//i.test(url) ? `http://${url}` : url;

  renderTile = (e) => {
    const { votingBartenderId, votedBartenderId } = this.state;
    const alreadyVoted = votedBartenderId === propOr('', 'winner.id', e);
    const cursor = alreadyVoted ? 'default' : 'pointer';
    const [[color, background]] = [['white', colors.championshipBackground]]
      .map(a => alreadyVoted ? a : a.reverse());

    return (
      <Card key={e.id}>
        <PictureSection>
          {(e.winner || {}).image_url ? (
            <PictureImage src={e.winner.image_url} />
          ) : (
            <TransparentKeyWest src={keyWest} />
          )}
        </PictureSection>

        <InfoSection>
          <InfoHeader>
            {propOr('winner', 'winner.name', e).toUpperCase()}
          </InfoHeader>

          <InfoBody>
            <div>{propOr('', 'winner.bar', e)}</div>
            <div>{propOr('', 'city', e)}</div>
            {(e.winner || {}).cocktail && (
              <div>
                &#8220;
                {e.winner.cocktail}
                &#8221;
              </div>
            )}
          </InfoBody>
        </InfoSection>

        {!isEmpty(e.winner) && (
          <CharitySection>
            {!isEmpty(e.winner.local_charity_url) && !isEmpty(e.winner.local_charity_name) && (
              <a
                href={this.checkHttp(e.winner.local_charity_url)}
                style={{ width: '45%', overflow: 'hidden' }}
                target="_blank"
                rel="noopener noreferrer"
              >
                {charsToUpper(30, e.winner.local_charity_name)}
              </a>
            )}

            {!isEmpty(e.winner.stoli_charity_url) && !isEmpty(e.winner.stoli_charity_name) && (
              <a
                href={this.checkHttp(e.winner.stoli_charity_url)}
                style={{ width: '45%', overflow: 'hidden' }}
                target="_blank"
                rel="noopener noreferrer"
              >
                {charsToUpper(30, e.winner.stoli_charity_name)}
              </a>
            )}
          </CharitySection>
        )}

        {!isEmpty(e.winner) && (
          <VoteButton
            style={{ color, background, cursor }}
            onClick={alreadyVoted || votingBartenderId
              ? () => {}
              : this.handleOnVoteClick(e.winner.id)}
          >
            {votingBartenderId === e.winner.id ? (
              <StyledSpinner src={Spinner} />
            ) : (
              alreadyVoted ? 'SUBMITTED' : 'VOTE'
            )}
          </VoteButton>
        )}
      </Card>
    );
  }

  render = () => {
    const { events } = this.state;
    const { showBirthDateModal, showVoteAcceptedModal, showVoteRejectedModal } = this.state;
    const hideOverflow = showBirthDateModal || showVoteAcceptedModal || showVoteRejectedModal;

    return (
      <Wrapper className={hideOverflow && 'hide-overflow'}>
        <SEO
          title="Stoli® Vodka"
          description="Stoli® Vodka is the original premium vodka that
          combines modern distilling of the highest quality spirit with
          century old traditions to create its bold character."
        />

        <Layout className={showBirthDateModal && 'blur'}>
          <RainbowLine />

          <HeaderContainer>
            <LogoContainer>
              <StyledKeyWest src={keyWest} />
            </LogoContainer>

            <HeaderText>
              <Title>
                VOTE FOR YOUR FAVOURITE BARTENDER!
              </Title>

              <Description>
                Fan Favourite voting opens on Wednesday, May 22nd at 3pm ET
                (Noon PT) and closes on Thursday, May 30th at 3pm ET (Noon PT).
                Vote once per day, every day. The two bartenders with the most
                votes receive immunity from elimination in the Key West
                Semi-Final events on Thursday, June 6th and Friday, June 7th,
                and one will be awarded title of 2019 Stoli Key West Fan
                Favourite Bartender at the Key West Pride Main Stage Finale
                on June 8th.
              </Description>
            </HeaderText>
          </HeaderContainer>

          <BodyContainer>
            {(events || []).map(this.renderTile)}

            <AdditionalInfoWrapper>
              <AdditionalInfo>
                *Tyler Booth declined to compete in finale. 2019 Champion for Los
                Angeles by succession, Tamara Malais. Tamara tied for first place in
                2018 and ultimately placed second only by sudden death shot round
                tie-breaker.
              </AdditionalInfo>
              <ScheduleSection />
            </AdditionalInfoWrapper>
          </BodyContainer>
        </Layout>
        <Footer />
        {showBirthDateModal && <BirthDateModal onSubmit={this.onBirthDateModalSubmit} />}
        {this.renderVotedModal()}
        {this.renderLoginIntoFBModal()}
        {this.renderVotingNotStartedModal()}
        {this.renderVotingEndedModal()}
      </Wrapper>
    );
  }
}

const rainbowGradient = `linear-gradient(
  to left,
  red 0%,
  #FAF100 42%,
  #258F21 60%,
  #215E75 70%,
  #2428C5 80%,
  #570DAC 90%,
  #6E019F 100%
)`;

const Wrapper = styled.div`
  background: white;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  overflow: auto;
`;

const Layout = styled.div`
  width: 100%;
  height: 100%;
  background-image: linear-gradient(
    to bottom,
    #FEFEFB 30%,
    #FDFDFF,
    #FEFEFE,
    #F5F5F5,
    #E9E9E9 80%
  );
`;

const RainbowLine = styled.div`
  height: 8px;
  width: 100%;
  background-image: ${rainbowGradient};
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 1rem;
  margin: 1rem 0 0 2rem;

  @media (max-width: ${breakpoints.md.max}px) {
    justify-content: center;
    align-items: center;
    margin: 0;
  }
`;

const LogoContainer = styled.div`
  width: auto;
  height: auto;
  margin-top: 5px;

  @media (max-width: ${breakpoints.md.max}px) {
    margin-top: 1px;
  }
`;

const StyledKeyWest = styled.img`
  height: 210px;
  background-color: white;

  @media (max-width: ${breakpoints.md.max}px) {
    height: 165px;
  }
`;

const HeaderText = styled.div`
  margin-left: 2rem;
  width: 730px;

  @media (max-width: ${breakpoints.md.max}px) {
    margin-left: 0;
    text-align: center;
  }
`;

const Title = styled.div`
  color: ${colors.championshipBackground};
  font-family: 'Tahoma-Bold';
  margin-bottom: 15px;
  font-size: 56px;
  line-height: 64px;

  @media (max-width: ${breakpoints.md.max}px) {
    padding: 5px 0;
    margin-top: 5px;
    font-size: 24px;
    line-height: 28px;
  }
`;

const Description = styled.div`
  padding-left: 5px;
  font-family: 'Tahoma';
  font-size: 10.2px;
  font-weight: 600;
  line-height: 19px;
  letter-spacing: 1px;

  @media (max-width: ${breakpoints.md.max}px) {
    padding-left: 0;
    font-weight: 500;
  }
`;

const BodyContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  margin: 0 0 1rem 3.9rem;

  @media (max-width: ${breakpoints.md.max}px) {
    margin: 0 0 1rem 1rem;
    justify-content: space-around;
  }
`;

const Card = styled.div`
  background: white;
  font-family: 'Tahoma';
  width: 290px;
  height: 430px;
  margin: 20px 18px 20px 0;
  box-shadow: 0 3px 10px 1px rgba(0, 0, 0, 0.15);

  @media (max-width: ${breakpoints.md.max}px) {
    width: 45%;
  }

  @media (max-width: ${breakpoints.sm.min}px) {
    width: 290px;
  }
`;

const PictureSection = styled.div`
  height: 220px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PictureImage = styled.div`
  height: 220px;
  width: 100%;
  background-image: url(${props => props.src});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center
`;

const TransparentKeyWest = styled.img`
  height: 185px;
  background-color: white;
  opacity: 0.3;
  filter: alpha(opacity=30);

  @media (max-width: ${breakpoints.md.max}px) {
    height: 165px;
  }
`;

const InfoSection = styled.div`
  padding: 0.5rem;
  margin: 0.4rem 0 0 0.8rem;
  height: 106px;
  line-height: 22px;
`;

const InfoHeader = styled.div`
  font-family: 'Tahoma-Bold';
  color: black;
  font-size: 14.5px;
  letter-spacing: 0.5px;
`;

const InfoBody = styled.div`
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.5px;
`;

const CharitySection = styled.div`
  height: 49.5px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  border-top: 1.5px #f0f0f0 solid;
  padding: 0.5rem 1rem 0.5rem 1.3rem;
  font-size: 11px;
  line-height: 15px;

  & a {
    text-decoration: none;
    color: #10265b;
    font-weight: 400;
  }
`;

const VoteButton = styled.button`
  width: 100%;
  height: 47px;
  font-family: 'Tahoma-Bold';
  font-size: 22px;
  letter-spacing: 2px;
  border: 4.5px solid;
  border-image: ${rainbowGradient} 1 1%;
  cursor: pointer;
`;

const StyledSpinner = styled.img`
  height: 24px;
`;

const VotedModalActions = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
`;

const VotedModalButton = styled.div`
  align-self: flex-end;
  padding: 12px 15px 1px 1px;
  cursor: pointer;
`;

const VotedModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const VotedModalHeader = styled.div`
  margin-bottom: 1.3rem;
  font-family: 'Tahoma-Bold';
  font-size: 16.5px;
  letter-spacing: 0.4px;
`;

const VotedModalBody = styled.div`
  text-align: center;
  color: #1b1b1b;
  font-family: 'Tahoma';
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0.4px;
  line-height: 22px;
  padding: 0;
  max-width: 385px;
  min-width: 200px;
  @media (max-width: ${breakpoints.md.max}px) {
    padding: 0 10px;
  }
`;

const AdditionalInfo = styled.div`
  font-family: 'Tahoma';
  font-size: 13px;
  max-width: 650px;
  margin: 40px 0;
`;

const AdditionalInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
  margin-bottom: 20px;

  @media (max-width: ${breakpoints.md.max}px) {
    margin-left: -1rem;
    padding: 0 5px;
  }
`;


export default FanFavourite;
